<template>
  <div class="down_icon" @click="handleClick">
    <img class="max-icon" src="@/assets/svg/down-max-ccc2.svg">
    <!-- <img class="min-icon" style="position: absolute; left: 18px; bottom: 3px;" src="@/assets/svg/down-min-fff.svg"> -->
  </div>
</template>

<script>
export default {
  methods: {
    handleClick() {
      // 在这里调用父组件传递过来的点击事件处理方法
      this.$emit('click');
    }
  }
}
</script>